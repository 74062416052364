import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../providers/user/UserProvider';
import { FiEdit3 } from 'react-icons/fi';
import EditModal from '../common/Modal';
import Button from '../common/Button';
import { useMutation } from '@apollo/client';
import { getMyUserQuery, updateMyUserMutation } from '../../queries';
import { type UpdateMyUserMutationVariables, type UpdateMyUserMutation } from '../../types/generated.types';

interface MyDetailsProps {
  editModal: string;
  setEditModal: React.Dispatch<React.SetStateAction<string>>;
  userEdit: UpdateMyUserMutationVariables;
  setUserEdit: React.Dispatch<React.SetStateAction<UpdateMyUserMutationVariables>>;
}

const MyDetails = ({
  editModal,
  setEditModal,
  userEdit,
  setUserEdit,
}: MyDetailsProps) => {
  const { user } = useUserContext();
  const [updateMyUser, { loading: updateLoading }] = useMutation<UpdateMyUserMutation>(updateMyUserMutation);

  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [updateError, setUpdateError] = useState<string | null>(null);

  useEffect(() => {
    if (editModal === '') {
      setUpdateError(null);
    };
  }, [editModal]);

  const updateUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (updateLoading) return;
    updateMyUser({
      variables: {
        phoneNumber: userEdit.phoneNumber,
        email: userEdit.email,
        password: userEdit.password,
      },
      refetchQueries: [{ query: getMyUserQuery }],
    }).then(() => {
      setEditModal('');
    }).catch((err) => {
      setUpdateError(err.message);
    });
  };

  const updateUserPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (updateLoading) return;
    if (userEdit.newPassword !== confirmNewPassword) {
      setUpdateError('Lösenorden matchar inte');
      return;
    }
    updateMyUser({
      variables: {
        password: userEdit.password,
        newPassword: userEdit.newPassword,
      },
      refetchQueries: [{ query: getMyUserQuery }],
    }).then(() => {
      setEditModal('');
    }).catch((err) => {
      setUpdateError(err.message);
    });
  };

  const handleEditPhone = () => {
    setEditModal('phone');
  };
  const handleEditEmail = () => {
    setEditModal('email');
  };
  const handleEditPassword = () => {
    setEditModal('password');
  };

  return (
    <div className='w-full flex flex-col gap-2'>
      {/* PHONE */}
      <div>
        <label className='label'>Telefonnummer</label>
        <div className='relative'>
          <input
            type='tel'
            className='input'
            placeholder='Telefonnummer'
            value={user?.phoneNumber ?? ''}
            readOnly
          />
          <button
            onClick={handleEditPhone}
            className='btn-secondary text-sm absolute right-2 top-2 bottom-2 aspect-square outline-none rounded flex items-center justify-center'
          >
            <FiEdit3 size={18} />
          </button>
        </div>
        <EditModal open={editModal === 'phone'} setOpen={setEditModal}>
          <h1 className='text-2xl font-600 text-center'>Byt telefonnummer</h1>
          <form onSubmit={updateUser}>
            <div className='my-10 flex flex-col'>
              <label className='label'>Telefonnummer</label>
              <input
                type='tel'
                className='input mb-2'
                placeholder='Telefonnummer'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, phoneNumber: e.target.value });
                }}
                value={userEdit.phoneNumber ?? ''}
                required
              />
              <label className='label'>Lösenord</label>
              <input
                type='password'
                className='input'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, password: e.target.value });
                }}
                value={userEdit.password}
                required
              />
            </div>
            {updateError && (
              <p className='text-red-500 text-sm -mt-5 mb-5 text-center'>{updateError}</p>
            )}
            <Button isLoading={updateLoading} primary>Spara</Button>
          </form>
        </EditModal>
      </div>

      {/* EMAIL */}
      <div>
        <label className='label'>E-post</label>
        <div className='relative'>
          <input type='email' className='input' value={user?.email ?? ''} readOnly />
          <button
            onClick={handleEditEmail}
            className='btn-secondary text-sm absolute right-2 top-2 bottom-2 aspect-square outline-none rounded flex items-center justify-center'
          >
            <FiEdit3 size={18} />
          </button>
        </div>
        <EditModal open={editModal === 'email'} setOpen={setEditModal}>
          <h1 className='text-2xl font-600 text-center'>Byt e-postadress</h1>
          <form onSubmit={updateUser}>
            <div className='my-10 flex flex-col'>
              <label className='label'>Email</label>
              <input
                type='email'
                className='input mb-2'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, email: e.target.value });
                }}
                value={userEdit.email ?? ''}
                required
              />
              <label className='label'>Lösenord</label>
              <input
                type='password'
                className='input'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, password: e.target.value });
                }}
                value={userEdit.password}
                required
              />
            </div>
            {updateError && (
              <p className='text-red-500 text-sm -mt-5 mb-5 text-center'>{updateError}</p>
            )}
            <Button isLoading={updateLoading} primary>Spara</Button>
          </form>
        </EditModal>
      </div>

      {/* PASSWORD */}
      <div>
        <label className='label' htmlFor=''>
          Lösenord
        </label>
        <div className='relative'>
          <input
            type='password'
            className='input'
            placeholder='Lösenord'
            required
            readOnly
            value={'********'}
          />
          <button
            onClick={handleEditPassword}
            className='btn-secondary text-sm absolute right-2 top-2 bottom-2 aspect-square outline-none rounded flex items-center justify-center'
          >
            <FiEdit3 size={18} />
          </button>
        </div>
        <EditModal open={editModal === 'password'} setOpen={setEditModal}>
          <h1 className='text-2xl font-600 text-center'>Byt lösenord</h1>
          <form onSubmit={updateUserPassword}>
            <div className='my-10 flex flex-col'>
              <label className='label'>Nytt lösenord</label>
              <input
                type='password'
                className='input mb-2'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, newPassword: e.target.value });
                }}
                value={userEdit.newPassword ?? ''}
                required
              />
              <label className='label'>Bekräfta nytt lösenord</label>
              <input
                type='password'
                className='input mb-2'
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                }}
                value={confirmNewPassword}
                required
              />
              <label className='label'>Tidigare lösenord</label>
              <input
                type='password'
                className='input'
                onChange={(e) => {
                  setUserEdit({ ...userEdit, password: e.target.value });
                }}
                value={userEdit.password}
                required
              />
            </div>
            {updateError && (
              <p className='text-red-500 text-sm -mt-5 mb-5 text-center'>{updateError}</p>
            )}
            <Button isLoading={updateLoading} primary>Spara</Button>
          </form>
        </EditModal>
      </div>
    </div>
  );
};

export default MyDetails;
