import React from 'react';
import Navigation from '../navigation/Navigation';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <div className='h-full flex flex-col'>
      <Navigation />
      <div className='flex-auto py-12 -mt-12 px-4 sm:px-6'>{children}</div>
    </div>
  );
};

export default AppLayout;
