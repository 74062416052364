import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../providers/user/UserProvider';
import Button from '../components/common/Button';

const Login = () => {
  const navigate = useNavigate();
  const { login, loginLoading, loginError } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loginLoading) return;
    login({ email, password });
  };

  const navigateToRegister = () => {
    navigate('/');
  };

  return (
    <div className='relative h-full'>
      <div className='mx-auto h-full w-full max-w-xl'>
        <form
          onSubmit={submit}
          className='h-full flex flex-col items-center justify-center'
        >
          <h2 className='title mb-10'>Logga in</h2>
          <div className='mb-5 w-full flex flex-col gap-2'>
            <div>
              <label className='label' htmlFor=''>
                E-mail
              </label>
              <input
                type='email'
                className='input'
                placeholder='E-mail'
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            <div>
              <label className='label' htmlFor=''>
                Lösenord
              </label>
              <input
                type='password'
                className='input'
                placeholder='Lösenord'
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </div>
            <Link
              to='/forgotpassword'
              className='text-sm text-gray-900 hover:underline max-w-max'
            >
              Glömt lösenord?
            </Link>
          </div>
          {loginError && (
            <p className='text-red-500 text-sm text-center max-w-md'>{loginError.message}</p>
          )}
          <Button
            primary
            isLoading={loginLoading}
            type='submit'
            className='mt-6 md:w-72'
          >
            Logga in
          </Button>
          <Button
            outline
            onClick={navigateToRegister}
            className='mt-3 md:w-72'
          >
            Skapa konto
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
