import React, { useEffect, useState } from 'react';
import MyDetails from '../components/profile/MyDetails';
import MyFobs from '../components/profile/MyFobs';
import EditModal from '../components/common/Modal';
import { useUserContext } from '../providers/user/UserProvider';
import Button from '../components/common/Button';
import { type DeleteMyUserMutation, type UpdateMyUserMutationVariables } from '../types/generated.types';
import { deleteMyUserMutation } from '../queries';
import { useMutation } from '@apollo/client';
import { useAuthContext } from '../providers/auth/AuthProvider';

const Profile = () => {
  const { logout } = useAuthContext();
  const { user } = useUserContext();
  const [deleteMyUser, { loading: deleteLoading, error: deleteError }] = useMutation<DeleteMyUserMutation>(deleteMyUserMutation);
  const [editModal, setEditModal] = useState<string>('');

  const [userEdit, setUserEdit] = useState<UpdateMyUserMutationVariables>({
    phoneNumber: '',
    email: '',
    password: '',
    newPassword: '',
  });

  useEffect(() => {
    setUserEdit({
      phoneNumber: user?.phoneNumber ?? '',
      email: user?.email ?? '',
      password: '',
      newPassword: '',
    });
  }, [user]);

  const deleteAccount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (deleteLoading) return;
    deleteMyUser({
      variables: {
        password: userEdit.password,
      },
    }).then(() => {
      logout();
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleDelete = () => {
    setEditModal('delete');
  };

  return (
    <div className='mx-auto my-auto h-full w-full flex flex-col items-center justify-center max-w-xl'>
      <h2 className='title mb-10'>Mitt konto</h2>
      <div className='w-full flex flex-col items-center gap-14'>
        <MyDetails
          editModal={editModal}
          setEditModal={setEditModal}
          userEdit={userEdit}
          setUserEdit={setUserEdit}
        />
        <MyFobs
          editModal={editModal}
          setEditModal={setEditModal}
          userEdit={userEdit}
          setUserEdit={setUserEdit}
        />
        <Button secondary onClick={handleDelete} className='md:w-72'>
          Radera konto
        </Button>
      </div>
      <EditModal open={editModal === 'delete'} setOpen={setEditModal}>
        <h1 className='text-2xl font-600 text-center'>Radera konto</h1>
        <form onSubmit={deleteAccount}>
          <div className='mt-10 mb-5'>
            <p className='text-sm'>
              Är du säker på att du vill radera ditt konto?
            </p>
            <p className='text-sm'>
              När du raderar ditt konto så kommer allt du har sparat hos oss
              raderas och du kommer inte kunna återställa det.
            </p>
          </div>
          <label className='label'>Lösenord</label>
          <input
            type='password'
            className='input mb-3'
            onChange={(e) => {
              setUserEdit({ ...userEdit, password: e.target.value });
            }}
            value={userEdit.password}
            required
          />
        </form>
        {deleteError && (
          <p className='text-red-500 text-sm -mt-5 mb-5 text-center'>{deleteError.message}</p>
        )}
        <Button isLoading={deleteLoading} primary>Radera konto</Button>
      </EditModal>
    </div>
  );
};

export default Profile;
