import React from 'react';

interface NavToggleProps {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavToggle = ({ isMenuOpen, setIsMenuOpen }: NavToggleProps) => {
  return (
    <button
      className={`relative right-2 sm:right-4 -top-1 sm:-top-0.5 text-secondary-200 rounded-full h-11 w-11 flex justify-center items-center focus:outline-none ${
        isMenuOpen ? ' bg-gray-50' : ''
      }`}
      onClick={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
      style={{ zIndex: 10000 }}
    >
      <span
        aria-hidden='true'
        className={`block absolute h-1 w-7 sm:w-8 rounded bg-current transform transition duration-300 ease-in-out ${
          isMenuOpen ? 'rotate-45' : '-translate-y-2'
        }`}
        style={{ height: 3.5 }}
      />
      <span
        aria-hidden='true'
        className={`block absolute h-1 w-7 sm:w-8 rounded bg-current transform transition duration-300 ease-in-out ${
          isMenuOpen ? 'opacity-0' : ''
        }`}
        style={{ height: 3.5 }}
      />
      <span
        aria-hidden='true'
        className={`block absolute h-1 w-7 sm:w-8 rounded bg-current transform  transition duration-300 ease-in-out ${
          isMenuOpen ? '-rotate-45' : 'translate-y-2'
        }`}
        style={{ height: 3.5 }}
      />
    </button>
  );
};

export default NavToggle;
