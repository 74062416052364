import { gql } from '@apollo/client';

// QUERIES
export const getMyUserQuery = gql`
  query User {
    user {
      _id
      phoneNumber
      email
      acceptedTerms
      role
      activated
      firstLogIn
      lastLogIn
      fobs {
        _id
        number
      }
    }
  }
`;

export const validateFobNumberQuery = gql`
  query ValidateFobNumber($fobNumber: String!) {
    validateFobNumber(fobNumber: $fobNumber)
  }
`;

export const myOrderQuery = gql`
  query Order($orderId: ObjectId!) {
    order(id: $orderId) {
      _id
      userId
      status
      price
      fobIds
      swishStatus
      swishNumber
    }
  }
`;

// MUTATIONS
export const loginUserMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const addUserMutation = gql`
  mutation AddUser(
    $email: String!
    $phoneNumber: String!
    $password: String!
    $longExpiry: Boolean!
    $acceptedTerms: Boolean!
  ) {
    addUser(
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      longExpiry: $longExpiry
      acceptedTerms: $acceptedTerms
    ) {
      accessToken
      refreshToken
    }
  }
`;

export const updateMyUserMutation = gql`
  mutation UpdateMyUser($password: String!, $newPassword: String, $email: String, $phoneNumber: String) {
    updateMyUser(password: $password, newPassword: $newPassword, email: $email, phoneNumber: $phoneNumber) {
      _id
    }
  }
`;

export const deleteMyUserMutation = gql`
  mutation DeleteMyUser($password: String!) {
    deleteMyUser(password: $password)
  }
`;

export const forgotPasswordMutation = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const resetPasswordMutation = gql`
  mutation ChangePasswordWithTmpPassword($email: String!, $tmpPassword: String!, $newPassword: String!) {
    changePasswordWithTmpPassword(email: $email, tmpPassword: $tmpPassword, newPassword: $newPassword) {
      _id
    }
  }
`;

export const resendActivationEmailMutation = gql`
  mutation ResendActivationEmail($email: String!) {
    resendActivationLink(email: $email)
  }
`;

export const activateUserMutation = gql`
  mutation ActivateUser($email: String!, $activationCode: String!) {
    activateUser(email: $email, activationCode: $activationCode)
  }
`;

export const createOrderMutation = gql`
  mutation CreateOrder($fobNumbers: [String!]!, $isTest: Boolean) {
    createOrder(fobNumbers: $fobNumbers, isTest: $isTest) {
      _id
      userId
      price
      fobIds
      status
    }
  }
`;

export const updateOrderMutation = gql`
  mutation UpdateOrder($orderId: ObjectId!, $fobNumbers: [String!]!) {
    updateOrder(orderId: $orderId, fobNumbers: $fobNumbers) {
      _id
      userId
      price
      fobIds
      status
    }
  }
`;

export const payOrderMutation = gql`
  mutation PayForOrder($orderId: ObjectId!, $phoneNumber: String!) {
    payForOrder(orderId: $orderId, phoneNumber: $phoneNumber)
  }
`;

export const deleteUserFobMutation = gql`
  mutation DeleteUserFob($deleteUserFobId: ObjectId!, $password: String!) {
    deleteUserFob(id: $deleteUserFobId, password: $password)
  }
`;
