import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { activateUserMutation } from '../queries';
import Button from '../components/common/Button';
import { FaChevronRight } from 'react-icons/fa';
import { type ActivateUserMutation } from '../types/generated.types';
import { useAuthContext } from '../providers/auth/AuthProvider';
import { AuthState } from '../types/enums';
import LoadingSpinner from '../components/common/LoadingSpinner';

const Activate = () => {
  const { email, code } = useParams();
  const navigate = useNavigate();
  const { authState } = useAuthContext();
  const [activateUser] = useMutation<ActivateUserMutation>(activateUserMutation);
  const [activationStatus, setActivationStatus] = useState('Aktiverar konto');

  useEffect(() => {
    activateUser({
      variables: {
        email,
        activationCode: code,
      }
    })
      .then(() => {
        setTimeout(() => {
          setActivationStatus('Kontot har aktiverats!');
        }, 2000);
      })
      .catch((err) => {
        if (err.message.includes('Användaren är redan aktiverad')) {
          setActivationStatus('Kontot är redan aktiverat');
        }
      });
  }, []);

  const navigateHome = () => {
    authState === AuthState.notLoggedIn ? navigate('/login') : navigate('/profile');
  };

  return (
    <div className='h-full flex flex-col justify-center items-center'>
      {activationStatus === 'Aktiverar konto' && <LoadingSpinner />}
      <h2 className='title'>{activationStatus}</h2>
      {activationStatus !== 'Aktiverar konto' && 
        <Button primary className='mt-8 md:w-72' onClick={navigateHome}>
        {authState === AuthState.notLoggedIn ? 'Logga in' : 'Mitt konto'}
        <FaChevronRight className='ml-1 -mb-0.5' size={14} />
      </Button>
      }
    </div>
  );
};

export default Activate;
