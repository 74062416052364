import { useClickOutside } from '@mantine/hooks';
import { motion } from 'framer-motion'
import React from 'react'

interface AlertProps {
  isVisible: boolean;
  title: string;
  message: string;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Alert = ({ isVisible, title, message, setIsVisible }: AlertProps) => {

  const close = () => {
    setIsVisible(false)
  };

  const alertRef = useClickOutside(() => {
    close();
  });

  return (
    <motion.div className='text-center flex fixed left-0 right-0 md:left-1/2 md:-translate-x-1/2 px-6' role='alert'
      ref={alertRef}
      initial={false}
      animate={isVisible ? { opacity: 1, bottom: 20 } : { opacity: 0, bottom: 0, pointerEvents: 'none' }}
      transition={{ duration: isVisible ? 0.5 : 0 }}>
      <motion.div className='relative w-full md:max-w-md mx-auto flex items-center justify-center gap-4 bg-red-100 border border-red-400 text-red-700 px-3 py-3 rounded-md'>
        <div className='flex flex-col items-center justify-center gap-2'>
          <p className='font-600'>{title}</p>
          <p className='text-sm md:max-w-sm'>{message}</p>
        </div>
        <span onClick={close} className='self-start absolute top-1.5 right-1.5'>
          <svg className='fill-current -mb-0.5 h-6 w-6 text-red-500' role='button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><title>Close</title><path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' /></svg>
        </span>
      </motion.div>
    </motion.div>
  )
}

export default Alert
