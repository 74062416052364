import React, { useState, useContext, useEffect } from 'react';
import AuthContext, { type IAuthContext } from './AuthContext';
import { AuthState, StorageKeys } from '../../types/enums';
import { useInterval } from '@mantine/hooks';

export const AuthProvider = (props: React.PropsWithChildren) => {

  const [authState, setAuthState] = useState<AuthState>(AuthState.initial);

  const [token, setToken] = useState(sessionStorage.getItem(StorageKeys.token));
  const [refreshToken, setRefreshToken] = useState(sessionStorage.getItem(StorageKeys.refresh));
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshTrigger = useInterval(() => {
    setRefreshCount((value) => value + 1);
  }, 290000);

  // Auth check
  useEffect(() => {
    if (token) {
      setAuthState(AuthState.isLoggedIn);
      refreshTrigger.start();

    } else {
      setAuthState(AuthState.notLoggedIn);
      refreshTrigger.stop();
    }
  }, [token]);

  const storeTokens = (accessT: string, refreshT: string) => {
    sessionStorage.setItem(StorageKeys.token, accessT);
    sessionStorage.setItem(StorageKeys.refresh, refreshT);
    setToken(accessT);
    setRefreshToken(refreshT);
  };

  const logout = () => {
    sessionStorage.removeItem(StorageKeys.token);
    sessionStorage.removeItem(StorageKeys.refresh);
    setToken(null);
    setRefreshToken(null);
  };

  const context: IAuthContext = {
    authState,
    token,
    refreshToken,
    refreshCount,
    setAuthState,
    storeTokens,
    logout,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
