import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthState } from '../types/enums';
import { useUserContext } from '../providers/user/UserProvider';

// This redirect is used for guests (not logged in users)
// Is user is logged in, redirect to either order page or profile page
const GuestRoute = ({ authState, ...props }: { authState: AuthState }) => {
  const location = useLocation();
  const { user } = useUserContext();
  return authState === AuthState.notLoggedIn ? (
    <Outlet />
  ) : (
    // Wait for user to be fetched
    user ? (
      // If user has no fobs, redirect to order page
      // Else redirect to profile page
      user.fobs && user.fobs.length === 0
        ? <Navigate to={'/order'} replace state={{ location }} />
        : <Navigate to={'/profile'} replace state={{ location }} />
    ) : null
  );
};

export default GuestRoute;
