import type React from 'react';
import { createContext } from 'react';
import {
  type LoginMutationVariables,
  type User,
} from '../../types/generated.types';
import { type ApolloError } from '@apollo/client';

export interface IUserContext {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  login: (user: LoginMutationVariables) => void;
  loginLoading: boolean;
  loginError: ApolloError | undefined;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const UserContext = createContext<IUserContext>({} as IUserContext);
export default UserContext;
