import type React from 'react';
import { createContext } from 'react';
import { type AuthState } from '../../types/enums';

export interface IAuthContext {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  token: string | null;
  refreshToken: string | null;
  refreshCount: number;
  storeTokens: (accessT: string, refreshT: string) => void;
  logout: () => void;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export default AuthContext;
