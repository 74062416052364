import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavToggle from './NavToggle';
import logo from '../../assets/img/logo.png';
import { AuthState } from '../../types/enums';
import { useAuthContext } from '../../providers/auth/AuthProvider';

interface MenuItem {
  name: string;
  path: string;
  state?: unknown;
}

const Navigation = () => {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { authState } = useAuthContext();

  const [isOpen, setIsOpen] = useState(false);
  const [navigation, setNavigation] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (authState === AuthState.isLoggedIn) {
      setNavigation([
        { name: 'Mitt konto', path: '/profile' },
        { name: 'Registrera ny nyckelbricka', path: '/order' },
      ]);
    } else {
      setNavigation([
        { name: 'Logga in', path: '/login' },
        { name: 'Skapa konto', path: '/' },
      ]);
    }
  }, [authState]);

  const navigateToHome = () => {
    authState === AuthState.isLoggedIn ? navigate('/profile') : navigate('/');
  };

  const handleLogout = () => {
    logout();
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      {/* Top */}
      <div
        className='mb-6 sm:mb-0 flex justify-between items-center'
        style={{ minHeight: 70 }}
      >
        <div
          className='px-4 flex items-center bg-secondary-100 text-white'
          style={{ zIndex: 1000 }}
        >
          {/* <img
            onClick={navigateToHome}
            src={logo}
            className='h-7 sm:h-8 w-auto cursor-pointer'
            alt='logo'
          /> */}
        </div>
        <NavToggle isMenuOpen={isOpen} setIsMenuOpen={setIsOpen} />
      </div>

      {/* Slideover menu */}
      <div
        className={`fixed bg-gray-50 text-gray-900 shadow-lg w-full md:w-1/4 top-0 bottom-0 transition-all duration-300 ${isOpen ? 'right-0' : '-right-full'
          }`}
        style={{ minWidth: 375, zIndex: 5000 }}
      >
        <div className='relative h-full w-full py-4 flex items-center'>
          <ul className='flex flex-col text-xl w-full'>
            {navigation.map((item, index) => (
              <li
                onClick={() => {
                  setIsOpen(false);
                }}
                key={index}
                className='cursor-pointer flex items-center border-b'
              >
                <Link
                  className='font-600 py-7 sm:py-10 w-full px-6 hover:bg-gray-100'
                  to={item.path}
                  state={item.state}
                >
                  {item.name}
                </Link>
              </li>
            ))}
            {authState === AuthState.isLoggedIn && (
              <li
                className='cursor-pointer flex items-center border-b'
                onClick={handleLogout}
              >
                <span className='py-7 sm:py-10 w-full px-6 hover:bg-gray-100 font-600'>
                  Logga ut
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navigation;
