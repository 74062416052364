import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { addUserMutation } from '../queries';
import {
  type AddUserMutation,
  type AddUserMutationVariables,
} from '../types/generated.types';
import Button from '../components/common/Button';
import { motion } from 'framer-motion';
import { IoCheckmarkSharp } from 'react-icons/io5';

const Register = () => {
  const navigate = useNavigate();
  const [registerUser, { loading: registerLoading, error: registerError }] =
    useMutation<AddUserMutation>(addUserMutation);

  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [user, setUser] = useState<AddUserMutationVariables>({
    email: '',
    password: '',
    phoneNumber: '',
    acceptedTerms: false,
    longExpiry: false,
  });

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (registerLoading) return;
    registerUser({ variables: user })
      .then((res) => {
        setIsCompleted(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  return (
    <div className='relative h-full'>
      <div className='mx-auto h-full w-full max-w-xl'>
        {!isCompleted ? (
          <form
            onSubmit={submit}
            className='h-full flex flex-col items-center justify-center'
          >
            <h2 className='title mb-10'>Skapa konto</h2>
            <div className='mb-5 w-full flex flex-col gap-2'>
              <div>
                <label className='label' htmlFor=''>
                  Telefonnummer
                </label>
                <input
                  type='tel'
                  className='input'
                  placeholder='Telefonnummer'
                  required
                  onChange={(e) => {
                    setUser({ ...user, phoneNumber: e.target.value });
                  }}
                  value={user.phoneNumber}
                />
              </div>
              <div>
                <label className='label' htmlFor=''>
                  E-mail
                </label>
                <input
                  type='email'
                  className='input'
                  placeholder='E-mail'
                  required
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                  value={user.email}
                />
              </div>
              <div>
                <label className='label' htmlFor=''>
                  Lösenord
                </label>
                <input
                  type='password'
                  className='input'
                  placeholder='Lösenord'
                  required
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                  value={user.password}
                />
              </div>
            </div>
            <div className='relative flex items-start'>
              <div className='flex h-5 items-center ml-1'>
                <input
                  required
                  id='agreement'
                  aria-describedby='agreement-description'
                  name='agreement'
                  type='checkbox'
                  className='h-5 w-5 rounded bg-slate-100 border-gray-400 focus:ring-offset-gray-50 ring-offset-gray-50 focus:border-gray-300 text-primary focus:ring-primary'
                  onChange={(e) => {
                    setUser({ ...user, acceptedTerms: e.target.checked });
                  }}
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='agreement' className='text-gray-800'>
                  Jag bekräftar att jag läst, förstått och godkänner <a href='https://www.narasecurity.com/allmanna-villkor' className='underline hover:text-primaryDark' target='_blank' rel='noreferrer'>allmänna villkor</a> och <a href='https://www.narasecurity.com/integritetspolicy' className='underline hover:text-primaryDark' target='_blank' rel='noreferrer'>integritetspolicy</a>
                </label>
              </div>
            </div>
            {registerError && (
              <p className='text-red-500 text-sm mt-5'>
                {registerError.message}
              </p>
            )}
            <Button
              primary
              isLoading={registerLoading}
              type='submit'
              className='mt-6 md:w-72'
            >
              Skapa konto
            </Button>
            <Button
              outline
              onClick={navigateToLogin}
              className='mt-3 md:w-72'
            >
              Logga in
            </Button>
          </form>
        ) : (
          <div className='h-full flex flex-col justify-center items-center'>
            <motion.div
              className='flex items-center justify-center w-14 h-14 rounded-full bg-primary mb-4 border-4'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <IoCheckmarkSharp size={32} className='text-white' />
            </motion.div>
            <h2 className='title mb-2 text-center'>Slutför din registrering</h2>
            <p className='font-600 mb-6 text-center'>
              Vi har skickat ett e-mail till dig med en länk för att aktivera
              ditt konto.
            </p>
            <div className='text-sm text-center'>
              <p>Vänligen kontrollera din e-mail.</p>
              <p>OBS! Om du ej fått något e-mail, kontrollera spamfiltret.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
