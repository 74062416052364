import React, { useState } from 'react';
import Button from '../components/common/Button';
import { useMutation } from '@apollo/client';
import { type ForgotPasswordMutation } from '../types/generated.types';
import { forgotPasswordMutation } from '../queries';

const ForgotPassword = () => {
  const [
    forgotPassword,
    { loading: forgotPasswordLoading, error: forgotPasswordError },
  ] = useMutation<ForgotPasswordMutation>(forgotPasswordMutation);
  const [email, setEmail] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (forgotPasswordLoading) return;
    forgotPassword({ variables: { email } })
      .then(() => {
        setIsCompleted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='relative h-full'>
      <form
        onSubmit={submit}
        className='mx-auto h-full w-full flex flex-col items-center justify-center max-w-xl'
      >
        <h2 className='title'>Glömt lösenord</h2>
        <div className='w-full mt-4 mb-8 lg:text-center'>
          <p>
            Skriv din emailadress så skickar vi ett e-mail där du kan återställa
            ditt lösenord
          </p>
        </div>
        <div className='w-full'>
          <label className='text-sm mb-1' htmlFor=''>
            Email
          </label>
          <input
            type='email'
            className='input'
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </div>
        {forgotPasswordError && (
          <p className='text-red-500 text-sm mt-5'>
            {forgotPasswordError.message}
          </p>
        )}
        {isCompleted && (
          <p className='font-600 text-sm mt-5'>
            Ett e-mail har skickats till {email}
          </p>
        )}
        <Button
          primary
          disabled={isCompleted}
          isLoading={forgotPasswordLoading}
          type='submit'
          className='mt-6 md:w-72'
        >
          Återställ lösenord
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
