import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { useAuthContext } from '../providers/auth/AuthProvider';

interface IApolloProps {
  children: React.ReactNode;
  serverUrl: string;
}

const Apollo: React.FC<IApolloProps> = ({ children, serverUrl }) => {

  const { token, logout } = useAuthContext();

  const httpLink = ApolloLink.from([
    onError(({ graphQLErrors }) => {
      graphQLErrors?.map(({ message, extensions }) => {
        switch (extensions.code) {
          case 'EXPIRED_REFRESH_TOKEN':
            logout();
            break;
          case 'EXPIRED_TOKEN':
            logout();
            break;
          default:
            break;
        }
        return message;
      });
    }),
    createUploadLink({
      uri: serverUrl,
      credentials: 'same-origin',
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        token: token ?? '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default Apollo;
