import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/common/Button';
import { useMutation, useQuery } from '@apollo/client';
import { myOrderQuery, payOrderMutation } from '../queries';
import { type OrderQuery, type Order, type PayForOrderMutation } from '../types/generated.types';
import { SwishStatus } from '../types/enums';
import LoadingSpinner from '../components/common/LoadingSpinner';

const OrderComplete = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { data: myOrderData } = useQuery<OrderQuery>(myOrderQuery, { variables: { orderId }, pollInterval: 4000 });
  const [payWithSwish, { loading: payWithSwishLoading }] = useMutation<PayForOrderMutation>(payOrderMutation);

  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    if (myOrderData?.order) {
      setOrder(myOrderData.order);
    }
  }, [myOrderData]);

  const navigateToProfile = () => {
    window.location.replace('/profile');
  };

  const navigateToCreateOrder = () => {
    navigate('/order');
  };

  const paymentRetry = () => {
    if (!order || payWithSwishLoading) return;

    payWithSwish({
      variables: {
        orderId: order._id,
        phoneNumber: order.swishNumber,
      },
      refetchQueries: [{ query: myOrderQuery, variables: { orderId } }],
    }).then(() => {
      console.log('Payment started');
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className='h-full flex flex-col justify-center items-center text-center max-w-xl mx-auto'>

      {/* Betalning pågår... */}
      {order?.status === 'pending' ? <>
        <LoadingSpinner />
        <h2 className='title mb-10'>Betalning pågår</h2>
        <p className='text-lg mb-10 max-w-sm'>
          Du kan nu öppna Swish-appen för att slutföra din betalning.
        </p>
      </>
        : <>
          {/* Betalning avbruten */}
          {order?.swishStatus === SwishStatus.cancelled && <>
            <h2 className='title mb-10'>Betalning avbruten</h2>
            <p className='text-lg mb-10 max-w-md'>
              Din betalning gick inte igenom. Du kan försöka igen eller välja att avbryta ditt köp.
            </p>
            <Button primary onClick={paymentRetry} isLoading={payWithSwishLoading} className='md:w-72'>
              Försök igen
            </Button>
            <Button secondary onClick={navigateToCreateOrder} className='mt-3 md:w-72'>
              Avbryt
            </Button>
          </>}

          {/* Betalning misslyckades */}
          {order?.swishStatus === SwishStatus.declined && <>
            <h2 className='title mb-10'>Betalning misslyckades</h2>
            <p className='text-lg mb-10 max-w-md'>
              Din betalning gick inte igenom. Du kan försöka igen eller välja att avbryta ditt köp.
            </p>
            <Button primary onClick={paymentRetry} isLoading={payWithSwishLoading} className='md:w-72'>
              Försök igen
            </Button>
            <Button secondary onClick={navigateToCreateOrder} className='mt-3 md:w-72'>
              Avbryt
            </Button>
          </>}

          {/* Oväntat fel vid betalning */}
          {order?.swishStatus === SwishStatus.error && <>
            <h2 className='title mb-10'>Oväntat fel</h2>
            <p className='text-lg mb-10 max-w-md'>
              Ett oväntat fel uppstod. Du kan försöka igen eller välja att avbryta ditt köp.
            </p>
            <Button primary onClick={paymentRetry} isLoading={payWithSwishLoading} className='md:w-72'>
              Försök igen
            </Button>
            <Button secondary onClick={navigateToCreateOrder} className='mt-3 md:w-72'>
              Avbryt
            </Button>
          </>}

          {/* Betalning slutförd */}
          {order?.swishStatus === SwishStatus.paid && <>
            <h2 className='title mb-10'>Tack för ditt köp!</h2>
            <p className='text-lg mb-10'>
              Du hittar dina registrerade nyckelbrickor i mitt konto.
            </p>
            <Button primary onClick={navigateToProfile} className='md:w-72'>
              Gå vidare
            </Button>
          </>}
        </>
      }
    </div>
  );
};

export default OrderComplete;
