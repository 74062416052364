import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import EditModal from '../common/Modal';
import Button from '../common/Button';
import { type DeleteUserFobMutation, type Fob, type UpdateMyUserMutationVariables } from '../../types/generated.types';
import { useUserContext } from '../../providers/user/UserProvider';
import { useMutation } from '@apollo/client';
import { deleteUserFobMutation, getMyUserQuery } from '../../queries';

interface MyKeysProps {
  editModal: string;
  setEditModal: React.Dispatch<React.SetStateAction<string>>;
  userEdit: UpdateMyUserMutationVariables;
  setUserEdit: React.Dispatch<React.SetStateAction<UpdateMyUserMutationVariables>>;
}

const MyFobs = ({
  editModal,
  setEditModal,
  userEdit,
  setUserEdit,
}: MyKeysProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [deleteUserFob, { loading: deleteFobLoading }] = useMutation<DeleteUserFobMutation>(deleteUserFobMutation);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const [fobs, setFobs] = useState<Fob[]>([]);

  useEffect(() => {
    if (user?.fobs) {
      setFobs(user.fobs);
    };
  }, [user?.fobs]);

  const submit = (e: React.FormEvent<HTMLFormElement>, fobId: string): void => {
    e.preventDefault();
    deleteUserFob({
      variables: {
        deleteUserFobId: fobId,
        password: userEdit.password,
      },
      refetchQueries: [{ query: getMyUserQuery }],
    }).then(() => {
      setEditModal('');
    }).catch((err) => {
      setDeleteError(err.message);
    });
  };

  const handleNavigateToAdd = (): void => {
    navigate('/order');
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <div>
        <p className='label'>
          {fobs.length > 0
            ? 'Mina registrerade nyckelbrickor'
            : 'Du har inga registrerade nyckelbrickor'}
        </p>
        <div className='flex flex-col gap-3'>
          {fobs.map((fob) => (
            <div key={fob._id} className='relative'>
              <PatternFormat
                className='input'
                type='tel'
                value={fob.number}
                format='### ###'
                placeholder='XXX XXX'
                readOnly
              />
              <button
                onClick={() => {
                  setEditModal(fob.number);
                }}
                className='btn-secondary text-sm absolute right-2 top-2 bottom-2 aspect-square outline-none rounded flex items-center justify-center'
              >
                <CgClose size={18} />
              </button>
              <EditModal open={editModal === fob.number} setOpen={setEditModal}>
                <h1 className='text-2xl font-600 text-center'>
                  Ta bort nyckelbricka
                </h1>
                <p className='text-center font-mono mt-1 bg-slate-200 px-1 font-semibold rounded max-w-max mx-auto'>
                  {fob.number.replace(/.{3}/g, '$& ')}
                </p>
                <form onSubmit={(e) => { submit(e, fob._id); }}>
                  <div className='mt-8 mb-5'>
                    <p className='font-500 text-sm'>
                      Är du säker på att du vill ta bort denna nyckelbricka?
                    </p>
                    <p className='font-500 text-sm'>
                      När du tar bort nyckelbrickan så kommer du inte kunna
                      återställa den.
                    </p>
                  </div>
                  <label className='label'>Lösenord</label>
                  <input
                    type='password'
                    className='input mb-3'
                    onChange={(e) => {
                      setUserEdit({ ...userEdit, password: e.target.value });
                    }}
                    value={userEdit.password}
                    required
                  />
                  {deleteError && (
                    <p className='text-red-500 text-sm -mt-5 mb-5 text-center'>{deleteError}</p>
                  )}
                  <Button isLoading={deleteFobLoading} primary>Ta bort nyckelbricka</Button>
                </form>
              </EditModal>
            </div>
          ))}
        </div>
      </div>
      <Button primary onClick={handleNavigateToAdd}>
        + Lägg till fler nyckelbrickor
      </Button>
    </div>
  );
};

export default MyFobs;
