import React, { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { motion } from 'framer-motion';
import { CgClose } from 'react-icons/cg';
import { type IFob } from '../../views/CreateOrder';
import { type UpdateOrderMutation, type CreateOrderMutation, type Order } from '../../types/generated.types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createOrderMutation, updateOrderMutation, validateFobNumberQuery } from '../../queries';

interface AddFobProps {
  order: Order | null;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;
  fobInputs: IFob[];
  setCodeInputs: React.Dispatch<React.SetStateAction<IFob[]>>;
  fobInput: IFob;
  index: number;
}

const AddFob = ({
  order,
  setOrder,
  fobInput,
  index,
  fobInputs,
  setCodeInputs,
}: AddFobProps) => {
  const [codeError, setCodeError] = useState<boolean>(false);

  const [validateFob] = useLazyQuery(validateFobNumberQuery);

  const fobInputWithoutSpaces = (input: string): string => {
    return input.replace(/\s/g, '');
  };

  // Set fob input to approved or not
  const handleFobValidation = (isValid: boolean) => {
    if (isValid) {
      const inputs = [...fobInputs];
      inputs[index].approved = true;
      setCodeInputs(inputs);
      setCodeError(false);
    } else {
      const inputs = [...fobInputs];
      inputs[index].approved = false;
      setCodeInputs(inputs);
      setCodeError(true);
    }
  };

  // Mutation to create order
  // Run only once when the first fob is added
  const [createOrder] = useMutation<CreateOrderMutation>(createOrderMutation, {
    onCompleted: (res) => {
      if (res.createOrder) {
        setOrder(res.createOrder);
        handleFobValidation(true);
      }
    },
    onError: (error) => {
      console.log(error);
      handleFobValidation(false);
    },
  });

  // Mutation to update order
  // Run every time a new fob is added or removed
  const [updateOrder] = useMutation<UpdateOrderMutation>(updateOrderMutation, {
    onCompleted: (res) => {
      if (res.updateOrder) {
        setOrder(res.updateOrder);
        handleFobValidation(true);
      }
    },
    onError: (error) => {
      console.log(error);
      handleFobValidation(false);
    },
  })

  // Add fob to order
  const addFobToOrder = () => {
    if (order == null) {
      void createOrder({
        variables: {
          fobNumbers: [fobInputWithoutSpaces(fobInput.code)],
          isTest: true,
        },
      });
    } else {
      const fobNumbers = [...fobInputs.map((fob) => fobInputWithoutSpaces(fob.code))];
      void updateOrder({
        variables: {
          orderId: order._id,
          fobNumbers,
        },
      });
    }
  };

  // Remove fob from order
  const removeCodeInput = () => {
    if (order) {
      if(order.fobIds.length === fobInputs.length) {
        const newFobNumbers = [...fobInputs.map((fob) => fobInputWithoutSpaces(fob.code))];
        newFobNumbers.splice(index, 1);
        // Remove from order
        void updateOrder({ variables: { orderId: order._id, fobNumbers: newFobNumbers } }).then(() => {
          const newFobInputs = [...fobInputs];
          // Remove input if there is more than one, otherwise clear input
          if (fobInputs.length > 1) {
            newFobInputs.splice(index, 1);
            setCodeInputs(newFobInputs);
          } else {
            newFobInputs[index].code = '';
            newFobInputs[index].approved = false;
            setCodeInputs(newFobInputs);
          }
        });
      } else {
        const newFobInputs = [...fobInputs];
        newFobInputs.splice(index, 1);
        setCodeInputs(newFobInputs);
      }
    }
  };

  // Validate if fob is 6 digits and not already approved
  useEffect(() => {
    if (fobInputWithoutSpaces(fobInput.code).length === 6 && !fobInput.approved) {
      validateFob({
        variables: {
          fobNumber: fobInputWithoutSpaces(fobInput.code),
        },
      }).then((res) => {
        if (res.data.validateFobNumber) {
          addFobToOrder();
        } else {
          handleFobValidation(false);
        }
      }).catch((error) => {
        console.log(error);
        handleFobValidation(false);
      });
    };
  }, [fobInput.code]);

  const handleCodeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newCodes = [...fobInputs];
    newCodes[index].code = e.target.value;
    setCodeInputs(newCodes);
  };

  return (
    <motion.div
      className='relative'
      initial={{ opacity: index > 0 ? 0 : 1, y: index > 0 ? 10 : 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className='relative flex-auto'>
        {/* Code input */}
        <PatternFormat
          type='tel'
          value={fobInput.code}
          onChange={handleCodeInput}
          format='### ###'
          required
          placeholder='XXX XXX'
          className={`input font-mono disabled:text-gray-800 ${fobInput.approved ? 'cursor-default' : ''} ${fobInput.code.length === 7 && codeError
            ? 'border-red-500 focus:border-red-500'
            : ''
            }`}
          readOnly={fobInput.approved}
        />
        <motion.svg
          className={'pointer-events-none absolute top-1/2 -translate-y-1/2 bg-transparent stroke-green-300 p-1 rounded right-11'}
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 512 512'
          height='1.7em'
          width='1.7em'
          xmlns='http://www.w3.org/2000/svg'
          animate={{
            background: fobInput.approved ? '#111827' : '#f1f5f9',
          }}
          transition={{ duration: 0.3 }}
        >
          <motion.path
            fill='none'
            strokeLinecap='square'
            strokeMiterlimit='10'
            strokeWidth='50'
            d='M416 128L192 384l-96-96'
            animate={{
              pathLength: fobInput.approved ? [0, 1, 1, 1] : 0,
              opacity: fobInput.approved ? [1, 1, 1, 1] : 0,
              scale: fobInput.approved ? [1, 1, 1.3, 1] : 0,
            }}
            transition={{ duration: 1 }}
          />
        </motion.svg>

        {/* If fobInput is approved or index is more than 1 */}
        {(fobInput.approved || index > 0) && (
          <button
            onClick={removeCodeInput}
            className='bg-gray-900 hover:bg-gray-900 text-white text-opacity-90 hover:text-opacity-100 text-sm absolute right-2 h-7 top-1/2 -translate-y-1/2 aspect-square rounded flex items-center justify-center'
          >
            <CgClose size={18} />
          </button>
        )}
      </div>

      {/* Code error */}
      {codeError && fobInput.code.length === 7 && (
        <div>
          <small className='text-red-500'>Felaktig kod</small>
        </div>
      )}
    </motion.div>
  );
};

export default AddFob;
