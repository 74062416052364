import React from 'react';
import { motion } from 'framer-motion';

const LoadingSpinner = () => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{ repeat: Infinity, duration: 1.5 }}
    className='w-9 h-9 mb-10 stroke-primaryDark'
  >
    <svg className='w-full h-full' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 2V6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 18V22' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.93 4.93L7.76 7.76' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.24 16.24L19.07 19.07' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 12H6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 12H22' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.93 19.07L7.76 16.24' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.24 7.76L19.07 4.93' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </motion.div>
)

export default LoadingSpinner;
