import React, { useState } from 'react';
import Button from '../components/common/Button';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { resendActivationEmailMutation } from '../queries';
import { type ResendActivationEmailMutation } from '../types/generated.types';

const NotActivated = () => {
  const { email } = useParams();
  const [resendEmail, { loading: resendLoading, error: resendError }] = useMutation<ResendActivationEmailMutation>(resendActivationEmailMutation);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleClick = () => {
    if (resendLoading) return;
    resendEmail({
      variables: {
        email,
      },
    }).then(() => {
      setIsCompleted(true);
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className='h-full flex flex-col justify-center items-center text-center'>
      <h2 className='title mb-2'>Välkommen!</h2>
      <p className='font-600 text-lg mb-6'>
        För att logga in behöver du aktivera ditt konto.
      </p>
      <div className='max-w-2xl text-sm'>
        <p>
          Vi har tidigare skickat en länk till dig för att aktivera ditt konto.
          Vänligen kontrollera din e-mail.
        </p>
        <p>Om du ej fått något e-mail - kontrollera spamfiltret/skräpposten.</p>
        <p className='mt-2'>
          Du kan också klicka nedan för att få en ny aktiveringslänk skickad
          till din angivna e-mail:
        </p>
      </div>
      {resendError && (
        <p className='text-red-500 text-sm mt-5'>
          {resendError.message}
        </p>
      )}
      {isCompleted && (
        <p className='font-600 text-sm mt-5'>
          Ett e-mail har skickats till {email}
        </p>
      )}
      <Button
        primary
        type='submit'
        onClick={handleClick}
        className='md:w-72 mt-10 mx-auto'
        isLoading={resendLoading}
        disabled={isCompleted}
      >
        Skicka ny bekräftelse
      </Button>
    </div>
  );
};

export default NotActivated;
