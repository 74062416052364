import React, { useState } from 'react';
import Button from '../components/common/Button';
import { useMutation } from '@apollo/client';
import { resetPasswordMutation } from '../queries';
import { useParams } from 'react-router';
import { type ChangePasswordWithTmpPasswordMutation } from '../types/generated.types';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const params = useParams();
  const [resetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] = useMutation<ChangePasswordWithTmpPasswordMutation>(resetPasswordMutation);

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [isCompleted, setIsCompleted] = useState(false);
  const [clientError, setClientError] = useState<string | null>(null);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (resetPasswordLoading) return;
    if (newPassword !== confirmNewPassword) {
      setClientError('Lösenorden matchar inte');
      return;
    }
    resetPassword({
      variables: {
        email: params.email,
        tmpPassword: params.code,
        newPassword,
      }
    }).then(() => {
      setClientError(null);
      setIsCompleted(true);
    }).catch((err) => {
      setClientError(null);
      console.log(err);
    });
  };

  return (
    <div className='relative h-full'>
      <div className='mx-auto h-full w-full max-w-xl'>
        <form
          onSubmit={submit}
          className='h-full flex flex-col items-center justify-center'
        >
          <h2 className='title mb-10'>Återställ lösenord</h2>
          <div className='mb-5 w-full flex flex-col gap-2'>
            <div>
              <label className='label' htmlFor=''>
                Välj ett lösenord
              </label>
              <input
                type='password'
                className='input'
                placeholder='Lösenord'
                required
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                value={newPassword}
              />
            </div>
            <div>
              <label className='label' htmlFor=''>
                Bekräfta nytt lösenord
              </label>
              <input
                type='password'
                className='input'
                placeholder='Bekräfta nytt lösenord'
                required
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                }}
                value={confirmNewPassword}
              />
            </div>
          </div>
          {clientError && (
            <p className='text-red-500 text-sm'>{clientError}</p>
          )}
          {resetPasswordError && (
            <p className='text-red-500 text-sm'>{resetPasswordError.message}</p>
          )}
          {isCompleted && (
            <p className='font-600 text-sm'>
              Lösenordet har uppdaterats. Du kan nu <Link to='/login' className='underline hover:text-primaryDark'>logga in</Link> med ditt nya lösenord.
            </p>
          )}
          <Button
            primary
            disabled={isCompleted}
            isLoading={resetPasswordLoading}
            type='submit'
            className='mt-6 md:w-72'
          >
            Spara
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
