import React, { useState } from 'react';
import AddFob from '../components/order/AddFob';
import Swish from '../assets/img/Swish.svg';
import { motion } from 'framer-motion';
import Button from '../components/common/Button';
import { type PayForOrderMutation, type Order } from '../types/generated.types';
import { useMutation } from '@apollo/client';
import { payOrderMutation } from '../queries';
import { useNavigate } from 'react-router-dom';

export interface IFob {
  code: string;
  approved: boolean;
}

const CreateOrder = () => {

  const navigate = useNavigate();

  const [fobInputs, setFobInputs] = useState<IFob[]>([
    { code: '', approved: false },
  ]);

  const [order, setOrder] = useState<Order | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [payWithSwish, { loading: payWithSwishLoading }] = useMutation<PayForOrderMutation>(payOrderMutation);

  const addFobInput = () => {
    // Only possible to add more fobs if last fob is approved
    if (!fobInputs[fobInputs.length - 1].approved) return;

    const fobs = [...fobInputs];
    fobs.push({ code: '', approved: false });
    setFobInputs(fobs);
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!order || payWithSwishLoading) return;

    payWithSwish({
      variables: {
        orderId: order._id,
        phoneNumber,
      },
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      navigate(`/order/${order._id}`);
    }).catch((err) => {
      console.log(err);
    });

  };

  return (
    <div className='mx-auto my-auto h-full w-full flex flex-col max-w-xl'>
      <div className='my-auto'>
        <h2 className='title mt-10 mb-20 text-center'>
          Registrera din nyckelbricka
        </h2>
        <label className='label'>Ange koden på din nyckelbricka</label>
        {/* Add code inputs */}
        <div className='flex flex-col gap-2'>
          {fobInputs.map((fobInput, index) => (
            <AddFob
              key={index}
              fobInput={fobInput}
              index={index}
              order={order}
              setOrder={setOrder}
              fobInputs={fobInputs}
              setCodeInputs={setFobInputs}
            />
          ))}
          <Button primary onClick={addFobInput}>
            + Lägg till fler nyckelbrickor
          </Button>
        </div>
        {/* Pay with Swish */}
        <div className='mt-10'>
          <h2 className='text-2xl sm:text-3xl font-600 mt-8 mb-4 text-center'>
            Kassa
          </h2>
          <div className='max-w-max mx-auto text-lg text-center'>
            <p className='overflow-hidden'>
              Att betala:
              <span className='font-bold'>
                <motion.span
                  className='relative'
                  key={order?.price ?? 0}
                  initial={{
                    top: order?.price ? 5 : 0,
                    opacity: order?.price ? 0 : 1,
                  }}
                  animate={{ top: 0, opacity: 1 }}
                  transition={{ duration: 0.4 }}
                >
                  {' '}
                  {order?.price ?? 0}
                </motion.span>
                kr
              </span>
            </p>
          </div>
          <img className='h-24 mx-auto w-auto' src={Swish} alt='Swish logo' />
          <form onSubmit={submit}>
            <label className='label'>Mobilnummer</label>
            <input
              type='tel'
              className='input'
              placeholder='Mobilnummer'
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              value={phoneNumber}
              required
            />
            <p className='text-sm my-3'>
              Genom att betala godkänner jag{' '}
              <a href='https://www.narasecurity.com/allmanna-villkor' className='underline font-600 hover:text-primaryDark' target='_blank' rel='noreferrer'>
                köpvillkoren
              </a>
            </p>
            <Button isLoading={payWithSwishLoading} primary className='mt-2' disabled={!order?.fobIds.length}>
              Betala med Swish
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateOrder;
