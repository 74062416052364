import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useIdle, useViewportSize } from '@mantine/hooks';
import ProtectedRoute from './routes/ProtectedRoute';
import Profile from './views/Profile';
import GuestRoute from './routes/GuestRoute';
import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import CreateOrder from './views/CreateOrder';
import NotFound from './views/NotFound';
import Activate from './views/Activate';
import OrderPayment from './views/OrderPayment';
import NotActivated from './views/NotActivated';
import { AuthState } from './types/enums';
import AppLayout from './components/common/AppLayout';
import ResetPassword from './views/ResetPassword';
import { useAuthContext } from './providers/auth/AuthProvider';
import Alert from './components/common/Alert';
import { useMutation } from '@apollo/client';
import { refreshTokenMutation } from './queries';
import { type RefreshTokenMutation } from './types/generated.types';

const App = () => {
  const location = useLocation();
  const { height } = useViewportSize();
  const { authState, refreshToken, refreshCount, logout, storeTokens } = useAuthContext();
  const [refreshMutation] = useMutation<RefreshTokenMutation>(refreshTokenMutation);
  const isUserInactive = useIdle(300000);

  const [showInactivityAlert, setShowInactivityAlert] = useState(false);

  const handleRefreshMutation = () => {
    if (refreshToken) {
      refreshMutation({
        variables: {
          refreshToken,
        },
      }).then((res) => {
        const { accessToken, refreshToken: newRefreshToken } = res.data?.refreshToken ?? {};
        if (accessToken && newRefreshToken) {
          storeTokens(accessToken, newRefreshToken);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  // ACTIVE USER
  // Refresh token
  useEffect(() => {
    if (!isUserInactive && authState === AuthState.isLoggedIn) {
      handleRefreshMutation();
    }
  }, [refreshCount, authState, isUserInactive]);

  // INACTIVE USER
  // Logout and show alert
  useEffect(() => {
    if (isUserInactive && authState === AuthState.isLoggedIn) {
      logout();
      setShowInactivityAlert(true);
    }
  }, [isUserInactive]);

  return (
    <div style={{ height }} className='bg-slate-50 text-gray-900 overflow-y-auto font-500 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-rounded-full scrollbar-thumb-gray-300'>
      {authState !== AuthState.initial && (
        <AppLayout>
          <Routes location={location}>
            {/* Reachable for all */}
            <Route path='/activate/:email/:code' element={<Activate />} />
            <Route path='/reset-password/:email/:code' element={<ResetPassword />} />

            {/* Not inlogged in */}
            <Route path='/' element={<GuestRoute authState={authState} />}>
              <Route path='/' element={<Register />} />
              <Route path='/login' element={<Login />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
              <Route path='/:email/activate' element={<NotActivated />} />
            </Route>

            {/* Logged in */}
            <Route path='/' element={<ProtectedRoute authState={authState} />}>
              <Route path='/profile' element={<Profile />} />
              <Route path='/order' element={<CreateOrder />} />
              <Route path='/order/:orderId' element={<OrderPayment />} />
            </Route>

            {/* 404 */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AppLayout>
      )}
      <Alert
        title='Du har blivit utloggad'
        message='Du har varit inaktiv i mer än 5 minuter. Vänligen logga in igen för att fortsätta.'
        isVisible={showInactivityAlert}
        setIsVisible={setShowInactivityAlert}
      />
    </div>
  );
};

export default App;
