import React from 'react';

const NotFound = () => {
  return (
    <div className='h-full flex flex-col items-center justify-center sm:px-6 lg:px-8'>
      <div className='flex flex-col items-center justify-center w-full max-w-md px-4 space-y-3 rounded-md sm:px-6 sm:py-8 sm:space-y-5'>
        <h1 className='text-4xl sm:text-5xl text-gray-900'>404</h1>
        <div className='text-center'>
          <h2 className='text-2xl font-medium'>Kunde inte hitta sidan</h2>
          <p className='text-sm text-gray-700 mb-4'>
            Kontrollera URL och försök igen
          </p>
        </div>
        <div>
          <a href='/' className='btn btn-primary'>
            Till startsidan
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
