import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoCloseSharp } from 'react-icons/io5';

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}

const Modal = ({ open, setOpen, children }: ModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative'
        onClose={() => {
          setOpen('');
        }}
        style={{ zIndex: 9999999 }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-slate-50 rounded-2xl px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl w-full sm:px-6'>
                <div className='relative'>
                  <div
                    className='mt-7 mb-6 font-500'
                    // style={{ height: 'calc(100vh - 140px)' }}
                  >
                    <IoCloseSharp
                      size={28}
                      className='absolute right-0 sm:-right-2 -top-8 cursor-pointer'
                      onClick={() => {
                        setOpen('');
                      }}
                    />
                    {children}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
