export const enum StorageKeys {
  token = 'AUTH_TOKEN_NS',
  refresh = 'AUTH_REFRESH_NS',
}

export const enum AuthState {
  initial = 'INITIAL',
  notLoggedIn = 'NOT_LOGGED_IN',
  isLoggedIn = 'LOGGED_IN',
}

export const enum SwishStatus {
  paid = 'PAID',
  cancelled = 'CANCELLED',
  error = 'ERROR',
  declined = 'DECLINED',
}
