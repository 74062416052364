import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext, { type IUserContext } from './UserContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getMyUserQuery, loginUserMutation } from '../../queries';
import {
  type LoginMutation,
  type LoginMutationVariables,
  type User,
  type UserQuery,
} from '../../types/generated.types';
import { AuthState } from '../../types/enums';
import { useAuthContext } from '../auth/AuthProvider';

export const UserProvider = (props: React.PropsWithChildren) => {
  const navigate = useNavigate();

  const { authState, storeTokens } = useAuthContext();

  const [loginMutation, { loading: loginLoading, error: loginError }] = useMutation<LoginMutation>(loginUserMutation);
  const [getMyUser, { data: userData }] = useLazyQuery<UserQuery>(getMyUserQuery, { fetchPolicy: 'network-only' });

  const [user, setUser] = useState<User>();

  // Get user
  useEffect(() => {
    if (authState === AuthState.isLoggedIn) {
      void getMyUser();
    } else {
      setUser(undefined);
    }
  }, [authState, getMyUser]);

  // Set user
  useEffect(() => {
    if (userData?.user) {
      setUser({
        ...userData.user,
        password: '',
      });
    }
  }, [userData?.user]);

  const login = (userVars: LoginMutationVariables) => {
    loginMutation({
      variables: userVars,
    })
      .then((res) => {
        const { accessToken, refreshToken } = res.data?.login ?? {};
        if (accessToken && refreshToken) {
          storeTokens(accessToken, refreshToken);
        }
      })
      .catch((err) => {
        if (err.message === 'Användaren är inte aktiverad.') {
          navigate(`/${userVars.email}/activate`);
        }
      });
  };

  const context: IUserContext = {
    user,
    setUser,
    login,
    loginLoading,
    loginError,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
