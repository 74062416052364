import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthState } from '../types/enums';
import { useUserContext } from '../providers/user/UserProvider';

// This redirect is used for logged in users
// Is user is not logged in, redirect to login page
const ProtectedRoute = ({ authState, ...props }: { authState: AuthState }) => {
  const location = useLocation();
  const { user } = useUserContext();

  // Wait for user to be fetched
  if (!user) {
    return null;
  }

  return authState === AuthState.isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} replace state={{ location }} />
  );
};

export default ProtectedRoute;
